<template>
  <V3Panel title="Selected Round Stats">
    <div>Rounds (W/T): {{ this.statData.roundsWon }} / {{ this.statData.rounds }}</div>

    <div class="ps-table">
      <div class="ps-table-header">
        <div class="ps-table-header-cell">PLR</div>
        <div class="ps-table-header-cell">K</div>
        <div class="ps-table-header-cell">D</div>
        <div v-if="!this.statData.gridFlag" class="ps-table-header-cell">A</div>
        <div class="ps-table-header-cell">TrK</div>
        <div class="ps-table-header-cell">TrD</div>
        <div v-if="!this.statData.gridFlag" class="ps-table-header-cell">ADR</div>
      </div>

      <div v-if="!this.statData.gridFlag" class="ps-table-body">
        <template v-for="(player, idx) in this.statData.playerStats">
          <div class="ps-table-row" :key="idx">
            <div class="ps-table-cell">
              <img class="ps-cell-player-icon" :src="player.agent_icon_url" />{{ player.name }}
            </div>
            <div class="ps-table-cell">{{ player.kills }}</div>
            <div class="ps-table-cell">{{ player.deaths }}</div>
            <div class="ps-table-cell">{{ player.assists }}</div>
            <div class="ps-table-cell">{{ player.trade_kills }}</div>
            <div class="ps-table-cell">{{ player.traded_deaths }}</div>
            <div class="ps-table-cell">{{ player.damage }}</div>
          </div>
        </template>
      </div>
      <div v-else class="ps-table-body">
        <template v-for="(player, idx) in this.statData.playerStats">
          <div class="ps-table-row" :key="idx">
            <div class="ps-table-cell">
              <img class="ps-cell-player-icon" :src="player.agent_icon_url" />{{ player.name }}
            </div>
            <div class="ps-table-cell">{{ player.kills }}</div>
            <div class="ps-table-cell">{{ player.deaths }}</div>
            <div class="ps-table-cell">{{ player.trade_kills }}</div>
            <div class="ps-table-cell">{{ player.traded_deaths }}</div>
          </div>
        </template>
      </div>
    </div>

    <div class="ts">
      <div>Opening Duels</div>
      <div>First Kills (W/T): {{ this.statData.openingDuelsWins.fk }} / {{ this.statData.openingDuels.fk }}</div>
      <div>First Deaths (W/T): {{ this.statData.openingDuelsWins.fd }} / {{ this.statData.openingDuels.fd }}</div>

      <div class="ps-table">
        <div class="ps-table-header">
          <div class="ps-table-header-cell">PLR</div>
          <div class="ps-table-header-cell">FK</div>
          <div class="ps-table-header-cell">FK Win</div>
          <div class="ps-table-header-cell">FD</div>
          <div class="ps-table-header-cell">FD Win</div>
        </div>

        <div class="ps-table-body">
          <template v-for="(player, idx) in this.statData.playerStats">
            <div class="ps-table-row" :key="idx">
              <div class="ps-table-cell">
                <img class="ps-cell-player-icon" :src="player.agent_icon_url" />{{ player.name }}
              </div>
              <div class="ps-table-cell">{{ player.fk }}</div>
              <div class="ps-table-cell">{{ player.fk_win }}</div>
              <div class="ps-table-cell">{{ player.fd }}</div>
              <div class="ps-table-cell">{{ player.fd_win }}</div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="ws">
      <div>Weapon Kill Breakdown</div>
      <template v-for="(player, idx) in this.statData.playerStats">
        <div v-if="Object.keys(player.weapon_kills).length != 0" class="ws-header" :key="idx">
          <img class="ws-icon" :src="player.agent_icon_url" />{{ player.name }}
        </div>
        <template v-if="player.weapon_kills">
          <template v-for="(item, key) in player.weapon_kills">
            <div v-if="item.type === 'Weapon'" class="weapon-kill-cell" :key="key + idx + player.name">
              <img
                class="ps-cell-weapon-icon"
                :src="weaponsByName[key].kill_stream_icon_url"
                :alt="weaponsByName[key].name"
              />
              : {{ item.count }}
            </div>
            <div v-if="item.type === 'Ability'" class="weapon-kill-cell" :key="key + idx + player.name">
              <img class="ps-cell-weapon-icon" :src="getUtilityUrl(player.agent_name, key)" :alt="key" /> :
              {{ item.count }}
            </div>
          </template>
        </template>
      </template>
    </div>

    <div class="rs">
      <div>XvY Round Stats (3s No Death)</div>
      <div class="rs-header">
        <div class="ps-table-header-cell">XvY</div>
        <div class="ps-table-header-cell">Wins</div>
        <div class="ps-table-header-cell">Total</div>
      </div>

      <template v-for="(xvy, idx) in this.statData.roundStates">
        <div class="rs-header" :key="idx">
          <div>{{ xvy[0] }}</div>
          <div>{{ xvy[1].wins }}</div>
          <div>{{ xvy[1].total }}</div>
        </div>
      </template>
    </div>
  </V3Panel>
</template>

<script>
import { mapGetters } from 'vuex'

import exposedDataState from '../mixins/exposedDataState.js'

import V3Panel from './v3dafi/V3Panel.vue'

export default {
  name: 'SelectedRoundStatistics',
  components: { V3Panel },
  props: {
    isScrim: Boolean,
  },
  mixins: [exposedDataState],
  computed: {
    ...mapGetters({
      getAgentByName: 'static/getAgentByName',
      weaponsByName: 'static/weaponsByName',
    }),
    statData() {
      // NOTE: Still does not work well on multi-match where repeated teams have player/s on different agent
      // selected team - always available
      const selectedTeamId = this.state.selected.team
      const matchPlayers = Object.fromEntries(
        Object.entries(this.data?.matchPlayers || {}).filter(([, player]) => {
          if (player.team_id === selectedTeamId) {
            return true
          }
          return false
        })
      )
      // NEED this cause roundTeamStats players only use puuid but other stat filtering uses match_player_ids and the like
      const playersByPuuid = Object.fromEntries(
        Object.values(matchPlayers).map(player => {
          return [player.id, player]
        })
      )

      // selected matches by filter
      const selectedMatches = Object.fromEntries(
        Object.entries(this.data?.matches || {})
          .filter(([matchId]) => {
            if (this.state.filters.base.matches.length > 0) {
              return this.state.filters.base.matches.includes(matchId)
            }
            return true
          })
          .filter(([, match]) =>
            match.match_teams.some(matchTeamId => this.data.matchTeams[matchTeamId].team_id === selectedTeamId)
          )
      )

      // selected rounds by filter and user
      const selectedRounds = Object.fromEntries(
        Object.entries(this.data?.rounds || {}).filter(([roundId, round]) => {
          // check match is in filtered matches
          if (!(round.match_id in selectedMatches)) {
            return false
          }
          // check for various round filters
          return this.mapToolFilters.round(roundId)
        })
      )

      // Round Wins from data.roundTeams Data using selectedRounds (data.rounds)
      const teamRoundWins = Object.fromEntries(
        Object.entries(this.data?.roundTeams || {}).filter(([, round]) => {
          if (round.round_id in selectedRounds && round.team_id === selectedTeamId && round.win) {
            return true
          }
          return false
        })
      )

      const teamRounds = Object.fromEntries(
        Object.entries(this.data?.roundTeams || {}).filter(([, round]) => {
          if (round.round_id in selectedRounds && round.team_id === selectedTeamId) {
            return true
          }
          return false
        })
      )

      let selectedRoundStates = []
      Object.entries(selectedRounds).forEach(([, round]) => {
        selectedRoundStates.push(...round.round_player_count)
      })

      const roundStates = {}
      selectedRoundStates.forEach(round => {
        let currState = {
          teamAlive: 0,
          oppAlive: 0,
          win: false,
        }
        if (!round.stable) {
          return
        }
        Object.entries(round.state).forEach(([teamId, state]) => {
          if (teamId in teamRounds) {
            currState.teamAlive = state.count
            currState.win = teamId in teamRoundWins ? true : false
          } else {
            currState.oppAlive = state.count
          }
        })

        let stateKey = (teamAlive, oppAlive) => `${teamAlive}v${oppAlive}`
        if (!(stateKey(currState.teamAlive, currState.oppAlive) in roundStates)) {
          roundStates[stateKey(currState.teamAlive, currState.oppAlive)] = {
            wins: currState.win ? 1 : 0,
            total: 1,
          }
        } else {
          roundStates[stateKey(currState.teamAlive, currState.oppAlive)].wins += currState.win ? 1 : 0
          roundStates[stateKey(currState.teamAlive, currState.oppAlive)].total += 1
        }
      })
      const playerStats = {}

      const roundTeamStats = Object.values(
        Object.fromEntries(
          Object.entries(this.data?.roundTeamStats || {}).filter(([roundId]) => {
            if (roundId in selectedRounds) {
              return true
            }
            return false
          })
        )
      )
        .map(team => team.filter(t => t.id == selectedTeamId).map(t => t.players))
        .flat(2)

      let idAndAgent = (id, agent) => `${id}-${agent}`
      let grid_flag = typeof roundTeamStats[0] === 'undefined' || roundTeamStats[0].kills === null ? true : false
      // One is for GRID as there are no playerStats provided and second is for standard Pro Matches
      if (grid_flag) {
        Object.entries(playersByPuuid || {}).forEach(([, player]) => {
          playerStats[idAndAgent(player.player_id, player.agent_id)] = {
            agent_icon_url: this.data.agents[player.agent_id].display_icon_url,
            agent_name: this.data.agents[player.agent_id].name,
            name: this.data.players[player.player_id].name,
            kills: 0,
            deaths: 0,
            assists: 0,
            damage: 0,
            trade_kills: 0,
            traded_deaths: 0,
            fk: 0,
            fd: 0,
            fk_win: 0,
            fd_win: 0,
            weapon_kills: {},
          }
        })
      } else {
        roundTeamStats.forEach(player => {
          if (player.agent_name === undefined) {
            return
          }
          let agent_id = this.getAgentByName(player.agent_name).id
          if (!(idAndAgent(player.puuid, agent_id) in playerStats)) {
            playerStats[idAndAgent(player.puuid, agent_id)] = {
              agent_icon_url: player.agent_icon_url,
              agent_name: player.agent_name,
              name: player.name,
              kills: player.kills,
              deaths: player.deaths,
              assists: player.assists,
              damage: player.damage.total,
              trade_kills: 0,
              traded_deaths: 0,
              fk: 0,
              fd: 0,
              fk_win: 0,
              fd_win: 0,
              weapon_kills: {},
            }
          } else if (playerStats[idAndAgent(player.puuid, agent_id)].agent_icon_url == player.agent_icon_url) {
            playerStats[idAndAgent(player.puuid, agent_id)].kills += player.kills
            playerStats[idAndAgent(player.puuid, agent_id)].deaths += player.deaths
            playerStats[idAndAgent(player.puuid, agent_id)].assists += player.assists
            playerStats[idAndAgent(player.puuid, agent_id)].damage += player.damage.total
          }
        })
      }

      // Opening Duels - from data.firstRoundKills Data
      const openingDuelIds = Object.fromEntries(
        Object.entries(this.data?.firstRoundKills || {}).filter(([roundId]) => {
          if (roundId in selectedRounds) {
            return true
          }
          return false
        })
      )
      let firstKillWins = 0
      //From mapToolEvents Kills Data
      const firstKills = (this.mapToolEvents?.kills || []).filter(round => {
        if (
          round.round_id in openingDuelIds &&
          round.id == openingDuelIds[round.round_id]['kill_id'] &&
          !(round.victim.match_player_id in matchPlayers)
        ) {
          firstKillWins += round.round_team_id in teamRoundWins ? 1 : 0

          let killer = matchPlayers[round.killer.match_player_id]
          let idToUse = !grid_flag ? killer.puuid : killer.player_id

          if (idAndAgent(idToUse, killer.agent_id) in playerStats) {
            playerStats[idAndAgent(idToUse, killer.agent_id)]['fk'] += 1
            playerStats[idAndAgent(idToUse, killer.agent_id)]['fk_win'] += round.round_team_id in teamRoundWins ? 1 : 0
          }

          return true
        }
        return false
      })

      let firstDeathWins = 0
      //From mapToolEvents Kills Data
      const firstDeaths = (this.mapToolEvents?.kills || []).filter(round => {
        if (
          round.round_id in openingDuelIds &&
          round.id == openingDuelIds[round.round_id]['kill_id'] &&
          round.victim.match_player_id in matchPlayers
        ) {
          firstDeathWins += round.victim.round_team_id in teamRoundWins ? 1 : 0

          let victim = matchPlayers[round.victim.match_player_id]
          let idToUse = !grid_flag ? victim.puuid : victim.player_id

          if (idAndAgent(idToUse, victim.agent_id) in playerStats) {
            playerStats[idAndAgent(idToUse, victim.agent_id)]['fd'] += 1
            playerStats[idAndAgent(idToUse, victim.agent_id)]['fd_win'] +=
              round.victim.round_team_id in teamRoundWins ? 1 : 0
          }

          return true
        }
        return false
      })

      // HAVE TO TAKE INTO ACCOUNT UTILITY KILLS
      this.mapToolEvents.kills.forEach(kill => {
        let killer = kill.killer.match_player_id in matchPlayers ? matchPlayers[kill.killer.match_player_id] : ''
        if (kill.is_trade && kill.killer.match_player_id in matchPlayers) {
          let traded = matchPlayers[kill.traded.match_player_id]
          let killIdToUse = !grid_flag ? killer.puuid : killer.player_id
          let tradedIdToUse = !grid_flag ? traded.puuid : traded.player_id

          if (idAndAgent(killIdToUse, killer.agent_id) in playerStats)
            playerStats[idAndAgent(killIdToUse, killer.agent_id)]['trade_kills'] += 1
          if (idAndAgent(tradedIdToUse, traded.agent_id) in playerStats)
            playerStats[idAndAgent(tradedIdToUse, traded.agent_id)]['traded_deaths'] += 1
        }

        // OUR BACKEND PARSING INCLUDES Spike Damage in DamageTotal and Counts a Self-Kill as a Rewarded Kill
        if (kill.finishing_damage.damage_type === 'Bomb' && kill.killer.match_player_id in matchPlayers) {
          if (!grid_flag || !this.isScrim) {
            playerStats[idAndAgent(killer.puuid, killer.agent_id)]['damage'] -= 999
            playerStats[idAndAgent(killer.puuid, killer.agent_id)]['kills'] -= 1
          }
        }

        // WEAPON Kill Count
        if (!(kill.victim.match_player_id in matchPlayers) && kill.killer.match_player_id in matchPlayers) {
          let weapon_item
          if (kill.finishing_damage.damage_type === 'Weapon') {
            weapon_item = this.data.weapons[kill.finishing_damage.damage_item.toLowerCase()].name
          } else if (typeof kill.finishing_damage.damage_type !== 'undefined') {
            weapon_item =
              kill.finishing_damage.damage_item.charAt(0).toUpperCase() + kill.finishing_damage.damage_item.slice(1)
          }

          let idToUse = !grid_flag ? killer.puuid : killer.player_id
          if (idAndAgent(idToUse, killer.agent_id) in playerStats) {
            if (!(weapon_item in playerStats[idAndAgent(idToUse, killer.agent_id)]['weapon_kills'])) {
              playerStats[idAndAgent(idToUse, killer.agent_id)]['weapon_kills'][weapon_item] = {
                type: kill.finishing_damage.damage_type,
                count: 1,
              }
            } else {
              playerStats[idAndAgent(idToUse, killer.agent_id)]['weapon_kills'][weapon_item].count += 1
            }
          }

          // NO player stats in GRID so manual kill counting: NEED CUSTOM HERE
          if (grid_flag) {
            playerStats[idAndAgent(idToUse, killer.agent_id)]['kills'] += 1
          }
        }

        // NEED CUSTOM FOR GRID HERE
        if (grid_flag && kill.victim.match_player_id in matchPlayers) {
          let victim = matchPlayers[kill.victim.match_player_id]
          playerStats[idAndAgent(victim.player_id, victim.agent_id)]['deaths'] += 1
        }
      })

      const matchesCount = Object.entries(selectedMatches).length
      const roundsCount = Object.entries(selectedRounds).length
      const roundWinsCount = Object.entries(teamRoundWins).length

      for (const plr in playerStats) {
        playerStats[plr].damage = (playerStats[plr].damage / roundsCount).toFixed(0)
      }
      return {
        matches: matchesCount,
        rounds: roundsCount,
        roundsWon: roundWinsCount,
        roundStates: Object.entries(roundStates).sort((a, b) => (b[0] > a[0] ? 1 : a[0] > b[0] ? -1 : 0)),
        openingDuels: { fk: firstKills.length, fd: firstDeaths.length },
        openingDuelsWins: { fk: firstKillWins, fd: firstDeathWins },
        playerStats: playerStats,
        gridFlag: grid_flag,
      }
    },
  },
  methods: {
    getUtilityUrl(agentName, ability) {
      return this.getAgentByName(agentName).abilities.find(ab => ab.slot === ability).displayIcon
    },
  },

  // This whole section is for debugging, usually not needed
  watch: {
    // data: {
    //   handler(val) {
    //     console.log('data changed', val)
    //   },
    //   immediate: true,
    // },
    // mapToolEvents: {
    //   handler(val) {
    //     console.log('mapToolEvents changed', val)
    //   },
    //   immediate: true,
    // },
    // state: {
    //   handler(val) {
    //     // stringify -> parse to evaluate all computed properties
    //     console.log('state changed', JSON.parse(JSON.stringify(val)))
    //   },
    //   // DO NOT USE IN PRODUCTION
    //   // triggers when any of the state sub properties change
    //   deep: true,
    //   immediate: true,
    // },
    // statData: {
    //   handler(val) {
    //     console.log('statData changed', val)
    //   },
    //   immediate: true,
    // },
  },
}
</script>

<style scoped lang="scss">
.ts {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.ps {
  &-table {
    width: 100%;
    display: table;
  }

  &-table-header {
    display: table-header-group;
    font-weight: bold;
  }

  &-table-header-cell {
    display: table-cell;
    padding: 5px;
    text-align: justify;
    font-size: 0.625rem;
    color: #a4a2ad;
  }

  &-table-body {
    display: table-row-group;
  }

  &-table-row {
    display: table-row;
  }

  &-table-cell {
    display: table-cell;
    font-size: 0.75rem;
  }

  &-cell-player-icon {
    display: block;
    height: 1.375rem;
  }

  &-cell-weapon-icon {
    display: block;
    height: 0.75rem;
  }
}

.ws {
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  &-icon {
    display: inline;
    height: 1.375rem;
  }

  &-header {
    background-color: grey;
  }
}

.weapon-kill-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rs {
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
}
</style>
